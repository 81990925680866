import React from 'react';
import { useLocation } from 'react-router-dom';
import './FeedbackDisplay.css'; // Import CSS file for styling
import brandLogo from './robo1.png'; // Importing the brand logo

function splitText(text) {
    // Regular expression to match the JSON-like part inside square brackets
    const jsonPartRegex = /\[\s*(.*?)\s*\]/;
    const jsonPartMatch = text.match(jsonPartRegex);

    console.log('jsonPartMatch:', jsonPartMatch); // Debugging log

    let jsonDict = {};
    if (jsonPartMatch) {
        jsonPartMatch[1].split(',').forEach(item => {
            const [key, value] = item.split(':').map(part => part.trim());
            jsonDict[key.replace(/[^\w\s]/g, '')] = parseInt(value, 10); // Removing non-alphanumeric characters from keys
        });

        return {
            jsonDict,
            textualContent: text.slice(jsonPartMatch.index + jsonPartMatch[0].length).trim()
        };
    }

    return { jsonDict, textualContent: text };
}



const FeedbackDisplay = () => {
    const location = useLocation();
    const { feedbackData } = location.state || {};
    const text = splitText(feedbackData.feedback);

    if (!feedbackData) {
        return <p>No feedback to display.</p>;
    }

    const categories = Object.keys(text.jsonDict).map(category => ({
        name: category,
        rating: text.jsonDict[category],
        details: text.textualContent.split('\n\n').find(detail => detail.startsWith(category))
    }));

    return (
        <div className="feedback-container">
            <div className='header'>
                <h1 className="title">Your Profile Analysis</h1>
                <img src={brandLogo} alt="Brand Logo" className="brand-logo" />
            </div>
            {categories.map((category, index) => (
                <div key={index} className="category-card">
                    <h2 className="category-title">{category.name}</h2>
                    <div className="rating">
                        <div className="rating-bar" style={{ width: `${category.rating * 20}%` }}></div>
                    </div>
                    <p className="category-details">{category.details}</p>
                </div>
            ))}
            <div className="advice-section">
                <h2 className="advice-title">Constructive Advice</h2>
                <p className="advice-content">{text.textualContent.split('\n\n').slice(-1)[0]}</p>
            </div>
        </div>
    );
};


export default FeedbackDisplay;
