import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import UploadPage from './App';
import FeedbackDisplay from './FeedbackDisplay';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Privacy from './privacy';

// Function to get the 'devTest' query parameter
const getDevTestParam = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get('devTest');
};

// Determine which Stripe key to use based on the 'devTest' parameter
const isDevTest = getDevTestParam() === 'true';
const stripeKey = isDevTest 
  ? 'pk_test_4H5xPMUYJQZqVhUzdnyUW7qH' // Replace with your test key
  : 'pk_live_TQKde9pU0yCWRVwcPrrEWy50'; // Replace with your live key

const stripePromise = loadStripe(stripeKey);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <Router>
        <Routes>
          <Route path="/" element={<UploadPage />} />
          <Route path="/feedback" element={<FeedbackDisplay />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </Router>
    </Elements>
  </React.StrictMode>
);

reportWebVitals();
