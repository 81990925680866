import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import './App.css';
import brandLogo from './robo1.png'; // Assuming the logo is in the same directory
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import PaymentModal from './PaymentModal';
import ReactGA from 'react-ga';
import Compress from 'compress.js';


function UploadForm() {
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [stripeError, setStripeError] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [isDevTest, setIsDevTest] = useState(false); // Initialize isDevTest
    const [canMakePayment, setCanMakePayment] = useState(false);
    

    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        // Function to check if 'devTest' URL parameter is set to 'true'
        const checkDevTestParam = () => {
            const queryParams = new URLSearchParams(location.search);
            return queryParams.get('devTest') === 'true';
        };

        const devTestMode = checkDevTestParam();
        setIsDevTest(devTestMode); // Set isDevTest based on URL parameter

        setTransactionId(uuidv4());
        ReactGA.initialize('G-NGN2JDE8NL'); // Replace with your tracking ID

        if (devTestMode) {
            ReactGA.set({ dimension1: 'Test' }); // Use a custom dimension to mark as test
        }
    }, [location]); // Depend on location to re-run when URL changes

    

    useEffect(() => {
        // ... existing useEffect logic

        // New logic to check payment capability
        const checkPaymentCapability = async () => {
            if (!stripe) return;

            const paymentRequest = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: 'Profile Analysis Fee',
                    amount: 50, // Replace with your amount
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });

            const result = await paymentRequest.canMakePayment();
            setCanMakePayment(!!result);
        };

        checkPaymentCapability();
    }, [stripe]); // Depend on stripe to re-run when it's ready
    
    const getQueryParam = (param) => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get(param);
    }
    

    const handleStripePayment = async () => {
        if (!stripe || !elements) {
            setStripeError('Stripe not loaded.');
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error, token } = await stripe.createToken(cardElement);

        if (error) {
            alert(error.message);
            setStripeError(error.message);
        } else {
            processFormSubmission(token);
            setIsModalOpen(false);
        }
    };

    const handlePayment = async () => {
        try {
            if (!stripe || files.length === 0) {
            return;
            }

            const paymentRequest = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: 'Profile Analysis Fee',
                    amount: 50, // Replace with your amount
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });

            await paymentRequest.canMakePayment().then(async result => {
                alert('can make payment', result);
                if (result) {
                    alert('result', result);
                    if(!result.applePay && !result.googlePay){
                        alert('fallback', result);
                        //Fallback
                        console.log('should be setting modal open.')
                        setIsModalOpen(true);
                    } else {
                        alert('attempting to show', result);
                        debugger;
                        console.log('payment request', paymentRequest)
                        await paymentRequest.show().catch(error => {
                            console.error('Error showing payment request:', error);
                            // Fallback to modal in case of error
                            setIsModalOpen(true);
                        });
                        console.log('payment request show', paymentRequest.show())
                    }   
                } else {
                    alert('payment request not available', result);
                    setIsModalOpen(true);
                    console.error('Payment request not available');
                }
            });

            
            paymentRequest.on('token', async ({ complete, token, ...data }) => {
                // Handle the received token
                // Send token to your backend along with image files
                complete('success');
                processFormSubmission(token); // New function to handle form submission
            });
        } catch(error){
            alert(error);
        }
    };

    const processFormSubmission = async (paymentToken) => {
        setIsLoading(true); // Start loading
        try {
            const formData = new FormData();
            formData.append('token', paymentToken.id); // Include Stripe token
            formData.append('transactionId', transactionId);
            formData.append('devTest', getQueryParam('devTest') === 'true');

            files.forEach(file => formData.append('images', file));
    
            const response = await fetch('/analyze-profile', {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();
    
            if (data.status === 'payment_failed' || data.status === 'error') {
                // Handle payment or other errors
                console.error('Error:', data.message);
                alert('Looks like there was an error processing your payment, please try again.', data.message)
                // Optionally, navigate to an error page or display an error message
                setIsLoading(false);
            } else {
                // Navigate to feedback page on successful payment and analysis
                navigate('/feedback', { state: { feedbackData: data } });
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    const handleFileChange = async (event) => {
        ReactGA.event({
            category: isDevTest ? 'User - Test' : 'User',
            action: `Added File ${transactionId}`,
        });

        const newFiles = Array.from(event.target.files);
        const compress = new Compress();

        const resizedImages = await compress.compress(newFiles, {
            size: 4, // Max size in MB
            quality: 0.75, // Image quality
            maxWidth: 1400, // Max width in pixels
            maxHeight: 1400, // Max height in pixels
            resize: true // Resize if needed
        });

        const compressedFiles = resizedImages.map(img => {
            const base64str = img.data;
            const imgExt = img.ext;
            const imgFile = Compress.convertBase64ToFile(base64str, imgExt);
            return new File([imgFile], `compressed-${imgFile.name}`, {
                type: `image/${imgExt}`,
            });
        });

        const totalFiles = files.length + compressedFiles.length;

        if (totalFiles > 5) {
            const allowedNewFilesCount = 5 - files.length;
            const allowedNewFiles = compressedFiles.slice(0, allowedNewFilesCount);
            setFiles(prevFiles => [...prevFiles, ...allowedNewFiles]);
        } else {
            setFiles(prevFiles => [...prevFiles, ...compressedFiles]);
        }

        event.target.value = null;
    };

    const removeImage = (indexToRemove) => {
        setFiles(files.filter((_, index) => index !== indexToRemove));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        ReactGA.event({
            category: isDevTest ? 'User - Test' : 'User',
            action: `Clicked Submit ${transactionId}`,
        });
    
        if (!stripe || files.length === 0) {
            return;
        }
    
        const paymentRequest = stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
                label: 'Profile Analysis Fee',
                amount: 50, // Replace with your amount
            },
            requestPayerName: true,
            requestPayerEmail: true,
        });
    
        const result = await paymentRequest.canMakePayment();
        console.log('the result', result);
    
        if (result) {
            if (!result.applePay && !result.googlePay) {
                // Fallback to modal
                setIsModalOpen(true);
            } else {
                try {
                    await paymentRequest.show();
                } catch (error) {
                    console.error('Error showing payment request:', error);
                    alert(error);
                    setIsModalOpen(true);
                }
            }
        } else {
            console.error('Payment request not available');
            alert('not avail');
            setIsModalOpen(true);
        }
    
        paymentRequest.on('token', async ({ complete, token, ...data }) => {
            complete('success');
            processFormSubmission(token);
        });
    };
    
    

    return (
        <div className="upload-container">
            <div className="brand-header">
                <img src={brandLogo} alt="Brand Logo" className="brand-logo"/>
                <h1 className="brand-title">DateDroid.ai</h1>
            </div>  
            <div className="info-section">
                <b>Welcome to DateDroid.ai!</b> 
                <p>Upload screenshots of your complete dating profile - photos, bio, and all. Our AI digs into every detail, compatible with any dating app. Get ready for transformative feedback.</p>
                <p className="slogan"><i>"...the most insightful $.50 cents you will ever spend"</i></p>
                <p className="made-in"><strong>Made in Austin, Texas.</strong></p>
            </div>
            <form onSubmit={handleSubmit} className="upload-form">
                <label className="file-input-label">
                    Add up to 5 dating profile screenshots:
                    <input 
                        type="file" 
                        onChange={handleFileChange}
                        className="file-input"
                        multiple
                        disabled={files.length >= 5}
                    />
                </label>
                <button type="submit"className={`submit-button ${files.length === 0 ? 'disabled' : ''}`} disabled={!files.length}>Submit</button>
            </form>
            {isLoading && <div className="loader"></div>} {/* Render loader when loading */}
            <div className="image-preview-container">
                {files.map((file, index) => (
                    <div key={index} className="image-preview">
                        <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} />
                        <button className="remove-button" onClick={() => removeImage(index)}>Remove</button>
                    </div>
                ))}
            </div>
            <PaymentModal
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setIsLoading(false);
                }}
                onConfirm={handleStripePayment}
                stripeError={stripeError}
            />
             {/* Footer with icon links */}
             <div className="footer-icons">
                <a href="https://www.instagram.com/datedroid" target="_blank" rel="noopener noreferrer">
                    <img src="https://cdn.icon-icons.com/icons2/792/PNG/512/INSTAGRAM_icon-icons.com_65535.png" alt="Instagram" />
                </a>
            </div>
            <div className="privacy-policy-agreement">
                <p>By using this service, you agree to our <a href="/privacy" onClick={() => navigate('/privacy')}>Privacy Policy</a>.</p>
            </div>
        </div>
    );
}

export default UploadForm;
