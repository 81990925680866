import './PaymentModal.css';
import { CardElement } from '@stripe/react-stripe-js';

function PaymentModal({ isOpen, onClose, onConfirm, stripeError }) {
    return (
        <div className={`modal ${isOpen ? 'show' : ''}`}>
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <h2>Confirm Your Payment</h2>
                <p className="price-info">Profile Analysis Fee: $0.50</p>
                <div className="card-details">
                    <CardElement />
                    {stripeError && <p className="error">{stripeError}</p>}
                </div>
                <button className="confirm-btn" onClick={onConfirm}>Confirm Payment</button>
            </div>
        </div>
    );
}

export default PaymentModal;
