function Privacy() {
    return (
        <div>
            <p>This Privacy Policy describes how we collect, use, and share personal information in connection with DateDroid. We are committed to protecting your privacy and ensuring that your personal information is handled responsibly.</p>

            <b>Information We Collect</b>
            <p>We collect information necessary to process your payments when you use our Service. This may include:</p>
            <ul>
                <li>The last four digits of your payment card</li>
                <li>Your payment card expiration date</li>
                <li>Your first and last name</li>
                <li>Your billing and shipping address</li>
            </ul>

            <b>Photo Analysis Data</b>
            <p>When you use our service to analyze photos, we temporarily store the images to facilitate analysis. These images are securely transmitted to OpenAI for analysis and are deleted from our systems immediately after the analysis is complete.</p>

            <b>User Consent for Data Processing</b>
            <p>By using our service, you consent to the processing of your photos and related data by OpenAI. You acknowledge that this processing is essential for the service we provide.</p>

            <b>Use of Your Information</b>
            <p>We use your information to:</p>
            <ul>
                <li>Process and complete transactions</li>
                <li>Prevent, detect, and manage fraudulent or illegal activities</li>
                <li>Comply with legal requirements and enforce our terms</li>
            </ul>

            <b>Sharing Your Information</b>
            <p>We share your information with our payment processor, Stripe, to process payments. Stripe uses and processes your payment information, including your first and last name, in accordance with Stripe’s Privacy Policy. We do not share your photos or analysis results with third parties.</p>

            <b>Limitation of Liability</b>
            <p>We are not liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the service; (ii) any conduct or content of any third party on the service; (iii) any content obtained from the service; and (iv) unauthorized access, use, or alteration of your transmissions or content.</p>

            <b>Security</b>
            <p>We take reasonable measures to protect the information you provide to us. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>

            <b>Changes to This Privacy Policy</b>
            <p>We reserve the right to modify this Privacy Policy at any time. Changes will take effect immediately upon their posting on the Service. We encourage you to review this Privacy Policy periodically.</p>

            <b>Contact Us</b>
            <p>If you have any questions about this Privacy Policy, please contact us via our Instagram.  You can find the link to our Instagram by clicking the instagram icon on the bottom of the homepage.</p>
        </div>
    );
}

export default Privacy;
